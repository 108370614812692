.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.Map {
  position: absolute;
  height: 100vh;
  width: 100vh;
  z-index: 1;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
