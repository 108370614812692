.directions-container {
  display: flex;
  position: fixed;
  flex-direction: column;
  z-index: 1000;
  margin: 1rem;
  padding: 0.5rem;
  width: 80%;
  top: 0;
  bottom: 0;
  border-radius: 1%;
  right: 0;
  max-width: 400px;
  background-color: rgb(235, 233, 233);
  align-items: baseline;
  padding-right: 30px;
  border: 0.1em solid cornflowerblue;
  box-shadow: 8px 8px 10px 0px rgb(56, 56, 56);
  text-align: left;
  overflow: auto;
}

.travel-mode-buttons-container {
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  padding: 0.7rem;

  width: fit-content;
  align-items: baseline;
  padding-right: 30px;
}

.travel-icons {
  border-radius: 20%;
  padding: 0.7rem;
  margin-right: 1em;
  background-color: rgb(239, 242, 245);
}
.travel-icons:hover {
  background-color: rgb(195, 195, 195);
  transition: background-color 1s ease;
}

.travel-icons.active {
  background-color: cornflowerblue;
}

.cancel {
  position: absolute;
  right: 0;
}
