/**************************\ Directions UI  /**************************/

.container {
  display: flex;
  position: fixed;
  flex-direction: column;
  z-index: 1000;
  margin: 1rem;
  padding: 0.5rem;
  width: fit-content;
  background-color: white;
  align-items: baseline;
  padding-right: 30px;
  box-shadow: 6px 6px 8px 0px rgb(56, 56, 56);
}

.facility-icons {
  border-radius: 25%;
  font-size: 1em;
}
.icons {
  border-radius: 25%;
  padding: 0.05rem;
  position: absolute;
  right: 2px;
  margin: 0.05em;
}

.icons:hover {
  background-color: rgb(195, 195, 195);
  transition: background-color 1s ease;
}

.container-inner {
  display: flex;
  flex-direction: row;
  text-align: left;
}
/****************************** Filters *****************************/
.filter {
  position: absolute;
  right: 0px;
  background-color: transparent;
  padding: 0.05rem;
}

.filter-icons {
  border-radius: 25%;
  padding: 0.7rem;
  background-color: rgb(239, 242, 245);
}
.filter-icons:hover {
  background-color: rgb(195, 195, 195);
  transition: background-color 1s ease;
}

.filter-icons.active {
  background-color: rgb(159, 239, 255);
}

.green {
  color: green;
}
.yellow {
  color: rgb(204 184 74);
}
.red {
  color: rgb(206 62 62);
}

.infoview-directions {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 3em;
  width: 3em;
  margin: 0.3em;
}

.display-container {
  text-align: initial;
}

.search-query {
  position: fixed;
  left: 0;
  top: 7rem;
  z-index: 1000;
  margin: 1rem;
  padding: 0.5rem;
  width: fit-content;
  background-color: rgb(255, 255, 255);
  align-items: baseline;
  padding-right: 30px;
  border-radius: 3%;
  border-color: rgb(58, 37, 248);
  box-shadow: 6px 6px 8px 0px rgb(56, 56, 56);
}
